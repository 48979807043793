import React from "react";
import baie from "../pictures/baie.png";
import heater1 from "../pictures/heater1.png";
import heater from "../pictures/heater.png";
import pipes from "../pictures/pipes.png";
import underfloor from "../pictures/underfloor.png";
import service from "../pictures/service.jpg";
import heating from "../pictures/heating.png";

export const Services = () => {
  return (
    <div className="w-full h-full mt-24" id="services">
      <div className="w-full h-[500px] bg-gray-600/80 absolute">
        <img
          className="w-full h-full object-cover mix-blend-overlay"
          src={service}
          alt="/"
        />
      </div>

      <div className="max-w-[1240px] mx-auto  relative">
        <div>
          <h2 className=" pt-4 text-5xl text-yellow-800 font-bold text-center">
            Service
          </h2>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-2 sm:pt-20 text-black">
          <div className="bg-white rounded-xl shadow-2xl">
            <div>
              <img
                className="w-16 p-4 rounded-lg mt-[-2rem]"
                src={heater}
                alt="/"
              ></img>
              <h3 className="font-bold text-yellow-700 text-center">
                Central heating installations
              </h3>
              <p className="text-center">
                If you have purchased a central heating system and need
                assistance with its installation, We are fully prepared and
                available to help you. Let's proceed with the installation
                process and ensure your system is up and running efficiently.
              </p>
            </div>
          </div>
          <div className=" bg-white rounded-xl shadow-2xl">
            <div>
              <img
                className="w-16 p-4 rounded-lg mt-[-2rem]"
                src={heater1}
                alt="/"
              ></img>
              <h3 className="font-bold text-yellow-700 text-center">
                Boiler Installations
              </h3>
              <p className="text-center">
                If you're in need of installing or replacing an electric boiler
                to ensure a reliable hot water supply in your kitchen and
                bathroom, We are ready to assist you. As a certified plumber, we
                can handle the installation, replacement, or mounting of the
                boiler with expertise and professionalism.
              </p>
            </div>
          </div>
          <div className=" bg-white rounded-xl shadow-2xl">
            <div>
              <img
                className="w-16 p-4 rounded-lg mt-[-2rem]"
                src={pipes}
                alt="/"
              ></img>
              <h3 className="font-bold text-yellow-700 text-center">
                Pipe Installations
              </h3>
              <p className=" text-center">
                Repairs and installations of pipes and conduits - for both
                supply and drainage, regardless of their material - for
                individuals, homeowner associations, and companies.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-2xl">
            <div>
              <img
                className="w-16 p-4 rounded-lg mt-[-2rem]"
                src={underfloor}
                alt="/"
              ></img>
              <h3 className="font-bold text-yellow-700 text-center">
                Underfloor Heating
              </h3>
              <p className="text-center">
                When it comes to underfloor heating, each floor requires a
                unique approach. We will help you find the ideal solution for
                your needs.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-2xl">
            <div>
              <img
                className="w-16 p-4 rounded-lg mt-[-2rem]"
                src={heating}
                alt="/"
              ></img>
              <h3 className="font-bold text-yellow-700 text-center">
                Radiator Installations
              </h3>
              <p className="text-center">
                Whether you need to install new radiators or replace existing
                ones, we are here to help. Our experienced installers will
                provide the necessary expertise and ensure a smooth and
                efficient installation process.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-2xl">
            <div>
              <img
                className="w-16 p-4 rounded-lg mt-[-2rem]"
                src={baie}
                alt="/"
              ></img>
              <h3 className="font-bold text-yellow-700 text-center">
                Sanitary Product Installations
              </h3>
              <p className="text-center">
                We provide installation services for sanitary products. We are
                ready to repair, install, replace, and mount sanitary fixtures.
                You can confidently rely on our expertise in handling all your
                sanitary needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
