import React from "react";
import { BsYoutube, BsFacebook, BsInstagram, BsGoogle } from "react-icons/bs";
import check3 from "../pictures/check3.jpg";

export const Media = () => {
  return (
    <div className=" bg-gray-100 m-auto   gap-2">
      <div className="flex flex-col h-[200px] justify-center font-serif">
        <h3 className="text-3xl md:text-5xl font-bold text-yellow-800 text-center relative">
          + See my work
        </h3>
        <ul className="flex justify-center border-b-4 border-b-yellow-700 p-5">
          <li className=" text-red-700 text-4xl ">
            <a href="https://www.youtube.com/@MATEOn1389">
              <BsYoutube />
            </a>
          </li>
          <li className=" text-blue-700 text-4xl">
            <a href="https://www.facebook.com/Mateonplumbingandheating">
              <BsFacebook />
            </a>
          </li>
          <li className=" text-yellow-500 text-4xl">
            <a href="https://www.instagram.com/boceanc/">
              <BsInstagram />
            </a>
          </li>
          <li className=" text-blue-400 text-4xl">
            <a href="https://www.google.com/maps/place/MATE-On+PLUMBING+AND+HEATING/@51.4732174,-0.3986379,17z/data=!3m1!4b1!4m14!1m7!3m6!1s0x487673d912749437:0x201512aef4109668!2sMATE-On+PLUMBING+AND+HEATING!8m2!3d51.4732141!4d-0.396063!16s%2Fg%2F11t4dn5j5j!3m5!1s0x487673d912749437:0x201512aef4109668!8m2!3d51.4732141!4d-0.396063!16s%2Fg%2F11t4dn5j5j?entry=ttu">
              <BsGoogle />
            </a>
          </li>
          <li className=" text-4xl bg-gray-100">
            <a href="https://www.checkatrade.com/trades/mateonplumbingandheating1023736">
              <button>
                <img className=" h-9 w-19" src={check3} alt="/" />
              </button>
            </a>
          </li>
        </ul>
      </div>
      <div className=" flex flex-col text-sm text-gray-500 text-center p-3">
        <p>Copyright &copy;MATE-ON PLUMBING & HEATING</p>
        <p>Made by Berchez Adrian Victor</p>
      </div>
    </div>
  );
};
export default Media;
