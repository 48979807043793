import React from "react";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/Navbar";
import Mate from "./components/Mate";
import Contact from "./components/Contact";
import Services from "./components/Services";
import About from "./components/About";
import Media from "./components/Media";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Mate />
        <Services />
        <About />
        <Contact />
        <Media />
      </BrowserRouter>
    </>
  );
}

export default App;
