import React from "react";
import mate from "../pictures/mate.jpg";

export const Mate = () => {
  return (
    <div
      className="w-full h-screen bg-white flex flex-col justify-between"
      id="/"
    >
      <div className="grid md:grid-cols-2 max-w-[1240px] m-auto">
        <div className="flex flex-col justify-center md:items-start w-full px-2 py-8">
          <p className="text-2xl text-red-800 font-bold">MATE-ON PLUMBING &</p>
          <h1 className="py-3 text-6xl md:text-7xl font-bold text-red-800">
            HEATING
          </h1>
          <p className=" uppercase text-1xl text-red-800 ">
            Heating installation and repairs
          </p>
          <p className=" text-yellow-500 uppercase">
            Total bathroom installations
          </p>
          <p className=" text-blue-500 uppercase">General plumbing</p>

          <a href="https://tradehq.co.uk/mateonplumbingandheating/enquire">
            <button className="py-3 px6 sm:w-[60] my-4 hover:shadow-xl">
              REQUEST BOOKING/QUOTE
            </button>
          </a>
        </div>
        <div>
          <img className="w-full" src={mate} alt="mate" />
        </div>
      </div>
    </div>
  );
};
export default Mate;
