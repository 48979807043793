import React, { useState } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import logo2 from "../pictures/logo2.jpg";
import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleClick = () => setNav(!nav);
  const handleLinkClick = () => setNav(false);

  return (
    <div className="w-screen h-[100px] z-10 bg-white fixed drop-shadow-lg">
      <div className="px-2 flex justify-between items-center w-full h-full">
        <div className="flex  items-center">
          <img className=" h-20 w-15" src={logo2} alt="Logo" />
          <ul className="hidden md:flex">
            <li>
              <HashLink smooth to="#/" onClick={handleLinkClick}>
                Home
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#services" onClick={handleLinkClick}>
                Services
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#about" onClick={handleLinkClick}>
                About
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#contact" onClick={handleLinkClick}>
                Contact
              </HashLink>
            </li>
          </ul>
        </div>
        <div className="flex items-center " onClick={handleClick}>
          {!nav ? (
            <MenuIcon className="w-5 mr-4 block md:hidden" />
          ) : (
            <XIcon className="w-5 mr-4 block md:hidden" />
          )}
        </div>
      </div>
      <ul className={!nav ? "hidden" : "absolute  w-full px-8 bg-white"}>
        <li className="border-b-2 border-zinc-200 w-full">
          <HashLink smooth to="#/" onClick={handleLinkClick}>
            Home
          </HashLink>
        </li>
        <li className="border-b-2 border-zinc-200">
          <HashLink smooth to="#services" onClick={handleLinkClick}>
            Services
          </HashLink>
        </li>
        <li className="border-b-2 border-zinc-200">
          <HashLink smooth to="#about" onClick={handleLinkClick}>
            About
          </HashLink>
        </li>
        <li className="border-b-2 border-zinc-200">
          <HashLink smooth to="#contact" onClick={handleLinkClick}>
            Contact
          </HashLink>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
