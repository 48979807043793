import React from "react";
import { ElfsightWidget } from "react-elfsight-widget";
import { BsPhone } from "react-icons/bs";
import gas from "../pictures/gas.jpg";
import gas2 from "../pictures/gas2.jpg";

export const Contact = () => {
  return (
    <div className="" id="contact">
      <div>
        <div className=" m-auto   gap-4">
          <div className="flex flex-col h-[400px] justify-center">
            <h3 className="text-5xl md:text-6xl font-bold text-yellow-700 text-center relative">
              Let's get to work!
            </h3>
            <p className=" pb-6 pt-4  text-center ">
              Are you prepared to initiate your next project with us? Reach out
              to us by sending a message, and we will respond to you promptly!
            </p>
            <div className="flex justify-center">
              <a
                className=" hover:shadow-xl w-3/4 h-16"
                href="https://tradehq.co.uk/mateonplumbingandheating/enquire"
              >
                <button className=" hover:shadow-xl w-full h-16">
                  REQUEST BOOKING
                </button>
              </a>
            </div>
            <div>
              <p className=" pb-6 pt-4  text-center text-3xl text-gray-500 flex items-center justify-center">
                <BsPhone />
                +447960087434
              </p>
            </div>
          </div>
        </div>
        <div>
          <ElfsightWidget
            widgetId="fbe92d2c-703b-48ba-b9e5-dad0e5c1617d"
            lazy
            modern
          />
        </div>
        <div className="flex justify-center p-8 gap-6">
          <div>
            <img className=" h-20 w-30" src={gas} alt="/" />
          </div>
          <a href="https://www.checkatrade.com/">
            <img
              className=" h-20 w-30"
              src="https://www.checkatrade.com/Images/CAT_Approved_member_logo.jpg"
              alt="Checkatrade information for"
            />
          </a>
          <div>
            {" "}
            <img className=" h-20 w-30" src={gas2} alt="/" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
