import React from "react";
import pic1 from "../pictures/pic1.jpg";
import pic2 from "../pictures/pic2.jpg";
import pic3 from "../pictures/pic3.jpg";
import pic4 from "../pictures/pic4.jpg";
import pic5 from "../pictures/pic5.jpg";
import pic6 from "../pictures/pic6.jpg";

export const About = () => {
  return (
    <div className=" m-auto   gap-4" id="about">
      <div className="flex flex-col h-[400px] justify-center">
        <h3 className="text-5xl md:text-6xl font-bold text-yellow-800 text-center relative">
          About
        </h3>
        <p className=" pb-6 pt-4  text-center text-1xl p-8">
          Mate-On Plumbing and Heating is a small plumbing and heating company
          that strives to do the best job time and time again. Our goal is to
          keep loyal, returning customers. Unlike larger faceless companies,
          Mate-On plumbing and heating is a local family company that you will
          always be able to contact for your needs. References can be provided
          and we are Fully insured.
        </p>
      </div>

      <div className="grid grid-cols-3">
        <img
          className=" object-cover w-auto h-[200px] md:h-full p-2"
          src={pic1}
          alt="/"
        />
        <img
          className=" object-cover w-auto h-[200px] md:h-full p-2"
          src={pic2}
          alt="/"
        />
        <img
          className=" object-cover w-auto h-[200px] md:h-full p-2"
          src={pic3}
          alt="/"
        />
        <img
          className=" object-cover w-auto h-[200px] md:h-full p-2"
          src={pic4}
          alt="/"
        />
        <img
          className=" object-cover w-auto h-[200px] md:h-full p-2"
          src={pic5}
          alt="/"
        />
        <img
          className=" object-cover w-auto h-[200px] md:h-full p-2"
          src={pic6}
          alt="/"
        />
      </div>
    </div>
  );
};
export default About;
